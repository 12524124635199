.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  background-image: url('./bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -9;
  background-color: rgba(0, 0, 0, 0.6);
}

.card.vote-option,
.card.vote-result {
  border: 3px solid rgba(191, 209, 255, 0.125);
  cursor: pointer;
  margin-bottom: 10px;
}

.card.vote-option.selected {
  background: rgba(191, 209, 255, 0.285);
}

.vote-result .label {
  font-size: 120%;
}

.vote-result .voter {
  display: inline-block;
  background: rgba(191, 209, 255, 0.125);
  margin-top: 10px;
  margin-right: 5px;
  padding: 0.2em 0.6em;
  border-radius: 1em;
  font-size: 80%;
  color: #ccc;
}

.navbar.navbar-expand-lg.navbar-dark.bg-primary {
  background-color: rgba(var(--bs-primary-rgb), 0.7) !important;
}
